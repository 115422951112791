import Vue from 'vue';
import App from './App.vue';
import store from './store';
import i18n from './setup/i18n';

//plugins
import axios from './plugins/axios';
import './plugins/element';

import './plugins/gmap';

import './mixins';
import './filters';

// CSS
import '@/assets/scss/index.scss';
import { getBrandConfigFileMemo, getBrandKey, getEnv } from './utils/env';

Vue.config.productionTip = false;

/**
 * web app initilize
 * @param {string=} from - webapp or sdk
 */
function wiseAppinit(from = 'webapp') {

  /**
   * verygoodvault
   */
  window.addVgsScript = function(callback) {
    const vgsScript = document.createElement('script');
    vgsScript.id = 'vgs-script';
    const orgId = getEnv('VUE_APP_VGS_ORG_ID');
    vgsScript.src =
      'https://js.verygoodvault.com/vgs-show/1.3/'+orgId+'.js';
    vgsScript.type = 'text/javascript';
    if(callback)
      vgsScript.onload = callback;
    document.head.append(vgsScript);
  }

  /**
   * vgs collect
   */
  window.addVgsCollectScript = function(callback) {
    const vgsScript = document.createElement('script');
    vgsScript.id = 'vgs-collect-script';
    vgsScript.src =
      'https://js.verygoodvault.com/vgs-collect/2.11.0/vgs-collect.js';
    vgsScript.type = 'text/javascript';
    if(callback)
      vgsScript.onload = callback;
    document.head.append(vgsScript);
  }
  
  /**
   * vgs collect
   */
  window.addVgsCollectScript = function(callback) {
    const vgsScript = document.createElement('script');
    vgsScript.id = 'vgs-collect-script';
    vgsScript.src =
      'https://js.verygoodvault.com/vgs-collect/2.11.0/vgs-collect.js';
    vgsScript.type = 'text/javascript';
    if(callback)
      vgsScript.onload = callback;
    document.head.append(vgsScript);
  }

  //plugins
  Vue.use(axios);


  /**
   * brand config cache at first time
   */
  getBrandConfigFileMemo(getBrandKey()).then(() => {

    const router = require('./router').default;

    if (from === 'webapp') {
      new Vue({
        router: router({ mode: 'history' }),
        store,
        i18n,
        render: (h) => h(App)
      }).$mount('#app');
    } else {
    //from sdk

      const r = router({ mode: 'abstract' });
      new Vue({
        router: r,
        store,
        i18n,
        render: (h) => h(App)
      }).$mount('#app');

      r.replace('/');
    }
  })
}

window.wiseAppinit = wiseAppinit;
