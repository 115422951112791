import store from '@/store';
import axios from 'axios';

/**
 * get env variables
 * @param {*} name
 */
export const getEnv = (name = undefined) => {
  const activeEnv = store.getters['getActiveEnv'];
  if (activeEnv) {
        const env = CONFIG_ENV; // eslint-disable-line
    // eslint-disable-next-line no-process-env
    if (name) return env[activeEnv][name] || process.env[name];
    else return env[activeEnv];
  } else {
    // eslint-disable-next-line no-process-env
    return process.env[name];
  }
};

/**
 * set env
 * @param {*} name
 */
export const setEnv = (env) => {
  store.commit('setActiveEnv', env);
  window.analytics && window.analytics.identify();
};

/**
 * get brand config
 * @param {*} name 
 * @param {*} defaultValue 
 * @returns 
 */
export const getBrandConfig = (name = undefined, defaultValue = undefined) => {
  let defaultValue2 = defaultValue;
  if(name === undefined)
    defaultValue2 = {};
  
  const partner = getBrandKey() || 'solid';
  if(partner) {
    const config = getBrandConfigFileMemo(partner);
    if(config) {
      const defaultConfig = {...config};
      return name ? (defaultConfig[name] === undefined ? defaultValue2 :defaultConfig[name]) : defaultConfig;
    }
  }
  return name ? defaultValue2 : {};
}

/**
 * get brand config file
 * @returns 
 */
export const getBrandConfigFile =  () => {
  const cachedResult = {
    
  }
  return (brandName) => {
    if(cachedResult[brandName])
      return cachedResult[brandName];

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      try {
        const assetsUrl = getEnv('VUE_APP_ASSETS');
        const {data} = await axios.get(`${assetsUrl}/${brandName}/${brandName}.json`);
        cachedResult[brandName] = data;
        resolve(data)
      } catch(e) {
        cachedResult[brandName] = {};
        resolve({});
      }
    })
    
    
  }
}
/**
 * momo variable
 */
export const getBrandConfigFileMemo = getBrandConfigFile();

/**
 * get brand key
 * @returns {string} brand key
 */
export const getBrandKey = () => {
  return 'gorillaexpense';
}

/**
 * get brand env variables from config file
 */
export const getBrandEnv = (name = undefined, defaultValue = undefined) => {
  const envObject = getBrandConfig('env');
  if(!envObject) {
    return defaultValue;
  }
  const currentEnv = getEnv('VUE_APP_ENV');
  return (envObject[currentEnv] && envObject[currentEnv][name] !== undefined) ?  envObject[currentEnv][name] : defaultValue;

}

/**
 * get country codes
 */
export const getCountryCodes = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    try {
      const assetsUrl = getEnv('VUE_APP_ASSETS');
      const {data} = await axios.get(`${assetsUrl}/countryCodes.json`);
      resolve(data);
    } catch(e) {
      resolve([]);
    }
  })
}

let countryCodes = [];
async function countryCodesMemo() {
  countryCodes = await getCountryCodes();
}
countryCodesMemo();

/**
 * get document types
 * @returns 
 */
export const getDocumentTypes = () => {
  return [
    {
      type: 'ssn',
      last4: '####',
      full: '###-##-####',
    },
    {
      type: 'passport',
      last4: 'XXXX',
      full: Array.apply(null, Array(17)).map((_, i) => 'X'.repeat(i+4)) //min 4, max 20
    }
  ]
}

/**
 * get supported countries
 */
export const getSupportedCountries = () => {
  const supportedCountries = getBrandEnv('supportedCountries') || getBrandConfig('supportedCountries', ['US']);

  return countryCodes.map((country) => {
    if(country.name == 'United States') {
      return country;
    } else {
      country.identityMask =  {
        type: 'passport',
        last4: 'XXXX',
        full: Array.apply(null, Array(17)).map((_, i) => 'X'.repeat(i+4)) //min 4, max 20
      }
    }
    return country;
  })
    .filter(c => supportedCountries.includes(c.code));

}


/**
 * get country details from phone number
 * @param {string} phoneNumber 
 */
export const getCountryDetailsFromPhoneNumber = (phoneNumber) => {
  const supportedCountries = getSupportedCountries();
  return supportedCountries.find(c => phoneNumber.startsWith(c.dial_code));
}